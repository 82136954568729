import React, { Component } from "react";
import Card from "react-md/lib/Cards/Card";
import CardText from "react-md/lib/Cards/CardText";
import CardTitle from "react-md/lib/Cards/CardTitle";
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Media from "react-md/lib/Media/Media";
import MediaOverlay from "react-md/lib/Media/MediaOverlay";
import Divider from "react-md/lib/Dividers/Divider";
import UserLinks from "../UserLinks";
import Slider from "../Slider/Slider";
import config from "../../../data/SiteConfig";
import "./Home.scss";

class Home extends Component {
  render() {
    return (
      <div className="md-grid mobile-fix">
        <Grid className="slider-banner">
          <Cell size={12}>
            <Slider />
          </Cell>
        </Grid>
        <Grid className="title-wrapper">
          <Cell size={4} tabletSize={2}></Cell>
          <Cell size={4} tabletSize={4}>
            <div className="">
              <h2 className="md-headline centered">Other Ways We Can Help</h2>
              <Divider />
              <ul>
                <li>Want to change your N-Number?</li>
                <li>Need priority handling of your registration paperwork?</li>
                <li>Lose your registration certificate?</li>
                <li>Need copies of 337 Forms?</li>
                <li>Want to know the complete ownership history of an aircraft?</li>
                <li>Need help clearing up an old lien or “cloud” on the title?</li>
                <li>Need help renewing your registration?</li>
              </ul>
            </div>
          </Cell>
          <Cell size={4} tabletSize={2}></Cell>
        </Grid>
        <Card className="md-grid md-cell--6">
          <div className="centered">
            <CardText>
              <h2 className="md-headline">How can we help?</h2>
              <Divider />
              <p className="md-body-1">
                Want to discuss your specific needs?
              </p>
            </CardText>
          </div>
        </Card>
      </div>
    );
  }
}

export default Home;
